import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

const StartedPage = () => {
    const [input, setInput] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    }

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const result = await axios.post("https://requests.likeimakid.com/chatLikeImAKid", {
                chatMessage: [{
                    role: "user",
                    content: input
                }]
            });

            console.log(result)
            
            if (result.data) {
                setResponse(result.data);
            } else {
                setResponse("Sorry, we received an unexpected response.");
            }
        } catch (error) {
            console.log(error);
            setResponse("Sorry, we couldn't fetch the explanation.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="container middle-content">

        <div className="container">
            <h1>Enter any topic and let us make it easy to understand</h1>
            <div className="input-section">
                <input 
                    type="text" 
                    placeholder="Enter a topic..."
                    value={input}
                    onChange={handleInputChange}
                />
                <button onClick={handleSubmit}>Explain</button>
            </div>
            {loading && <div className="spinner"></div>}
            {response && (
                <div className="response-section">
                    <h2>Explanation:</h2>
                    <p className="response-content">{response}</p>
                </div>
            )}
        </div>
        </div>

    );
}

export default StartedPage;
