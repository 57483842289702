import React from 'react';
import { Link } from 'react-router-dom';

function SiteHeader() {
  return (
    <header>
      <img src="https://likeimakidimages.s3.amazonaws.com/likeImAKidLogoFormat1.png" alt="Logo Desktop" className="logo-website" />
      <img src="https://likeimakidimages.s3.amazonaws.com/likeImAKidMobileLogo.png" alt="Logo Mobile" className="logo-mobile" />
      <nav>
        <Link to="/">Home</Link>
        <Link to="/aboutus">About us</Link>
        <Link to="/signin">Sign in</Link>
        <Link to="/signup" className="cta">Sign Up Free Today!</Link>
      </nav>
    </header>
  );
}

export default SiteHeader;