import React from 'react';
import './App.css';
import SiteHeader from './SiteHeader'; // Adjust the path based on your directory structure
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import StartedPage from './StartedPage';

function App() {
  return (
    <Router>
      <div className="container">
        <SiteHeader />
        <Routes>
          <Route path="/" element={
            <div className="content-wrapper">
                <main>
                  <h1>Your AI Assistant for Making Learning Easier!</h1>
                  <p>
                  likeimakid.com, powered by OpenAI, is your digital learning companion dedicated to simplifying complex subjects. 
                  Whether you're a college student or a lifelong learner, we break down topics into easy-to-understand, 
                  kid-friendly explanations, ensuring knowledge is both accessible and enjoyable for everyone
                  </p>
                  {/* <div className="video-container">
                    <iframe 
                        title="YouTube Video"
                        width="100%" 
                        height="100%" 
                        src="https://www.youtube.com/embed/ySus5ZS0b94" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    ></iframe>
                  </div> */}
                  <Link to="/started" className="get-started-btn">Get Started</Link>
                </main>
              </div>
          } />
          <Route path="/started" element={<StartedPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
